<!-- 临床研究统计平台 1-->
<template>
  <div class="clinic">
    <banner-img :bannerImg="bannerImg" />
    <cases-title :titleVal="'临床研究统计分析平台'" />
    <Business :business="business" />
    <!-- 应用场景 -->
    <div class="applications-container">
      <Subhead :enVal="'Applications  '" :znVal="'应用场景'" />
      <div class="app-text">{{ appText | myTrim }}</div>
    </div>
  </div>
</template>

<script>
import bannerImg from "./components/BannerImg.vue"
import casesTitle from "./components/Title.vue"
import Business from "./components/Business.vue"
import Subhead from "./components/Subheading.vue"
export default {
  components: {
    bannerImg,
    casesTitle,
    Business,
    Subhead
  },
  data() {
    return {
      bannerImg: require("@/assets/images/cases/banner/banner1.png"),
      business: {
        text: "在临床研究工作中，科研人员尽管面对着庞大的医疗信息数据资源，但是由于统计分析工具学习难度大，时间耗费大，投入经费大，临床数据分析工具使用成本极高，甚至经常误用统计方法，阻碍科研工作者的科研成果输出，也浪费了宝贵的医疗信息数据资源。临床科研工作者需要高效便捷的医疗数据分析平台，能够高效完成数据预处理，提升数据资源利用率，并能以最优匹配的结果智能寻参、智能建模。",
        Imgs: [{
          url: require("@/assets/images/cases/content/clinic01.png")
        }]
      },
      appText: "临床研究统计分析平台是一款无需编程、零代码、零基础的在线分析云平台。目前平台已完成包括数据治理、基线分析、高级统计分析、智能 AI分析、智能图表5大功 能模块，39个子模块，100余种分析方法建设。能够基本满足临床研究的统计分析工作需要。针对临床研究中常见的问题设计的数据清洗模块，包括缺失数据剔除、智 能数据填补、异常值处理等十多项数据方法。平台提供了智能基线分析模块能够快制作三线表，让研究人员能够直观的了解不同分组的差异。让临床研究人员能够通过 该平台实现快速统计建模分析，实现一键展示分析结果的描述，自动生成模型相关森林图、列线图等图表。临床研究人员能够通过该平台实现快速机器学习建模分析， 利用机器学习及深度学习模型帮助探索影响因子并建立预测模型。"
    }
  }
}
</script>

<style lang="less" scoped>
</style>